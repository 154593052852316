@import "~styles/var";
.ExternalList.ExternalList.ExternalList {
  z-index: 2;
  position: relative;
  color: $pinkish-orange;
  &.inline li{
    display: inline;
    &:not(:last-of-type):after  {
      content: " | ";
      margin: 0 3px;
    }
  }
  // margin: 0 42px 0 24px;
  li {
    margin-bottom: 10px;
    a {
      color: $pinkish-orange;
      &:hover {
        color: #fff;
      }
    }
  }
}