@import 'bootstrap-4-grid';
@import "~styles/var";

body {
  margin: 0;
  font-family: 'Noto Sans TC', 'Microsoft JhengHei', sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $black;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
