@import 'bootstrap-4-grid/scss/grid.scss';
@import "~styles/var";

@keyframes leftAnimation0 {
  0% {left: -100%;}
  70% {left: 10px;}
  100% {left: 0;}
}

@keyframes leftAnimation0Back {
  0% {left: 0;}
  100% {left: -100%;}
}

@keyframes leftAnimation120 {
  0% {left: -100%;}
  70% {left: 130px;}
  100% {left: 120px;}
}

@mixin mixLeftAnimation {
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.Header {
  background-color: lighten(#fff, 7%);
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.32);
  .container {
    max-width: 960px;
    input[type=checkbox] { display: none; }
    ul {
      margin: 0;
      padding-left: 0;
      list-style: none;
    }
  }
  .logo {
    display: inline-block;
    background-size: cover;
  }
  .menu {
    background-color: #fff;
    a {
      color: $black;
    }
    .main {
      font-size: 18px;
      .active {
        color: $nice-blue;
      }
    }
    .sub {
      font-size: 15px;
      border-radius: 15px;
      background-color: $very-light-brown;
      ul {
        margin: 3px;
        padding: 12px 20px;
        border-radius: 15px;
        border: dotted 2px rgba($milk-chocolate, .5);
        li {
          &:hover{
            &.time a:before { background-image: url(./images/clock-hover@2x.png); }
            &.fare a:before { background-image: url(./images/fare-hover@2x.png); }
            &.traffic a:before { background-image: url(./images/traffic-hover@2x.png); }
            &.intro a:before { background-image: url(./images/intro-hover@2x.png); }
            &.booking a:before { background-image: url(./images/booking-hover@2x.png); }
            &.ticketShop a:before { background-image: url(./images/tickets-hover@2x.png); }
          }
          a:before  {
            top: -2px;
            content: '';
            width: 30px;
            height: 30px;
            margin-right: 7px;
            position: relative;
            display: inline-block;
            background: no-repeat;
            vertical-align: middle;
            background-size: contain;
            background-position: center;
          }
        }
        .time a:before { background-image: url(./images/clock@2x.png); }
        .fare a:before { background-image: url(./images/fare@2x.png); }
        .traffic a:before {
          width: 45px;
          height: 28px;
          background-image: url(./images/traffic@2x.png);
        }
        .intro a:before { background-image: url(./images/intro@2x.png); }
        .booking a:before { background-image: url(./images/booking@2x.png); }
        .ticketShop a:before { background-image: url(./images/tickets@2x.png); }
        
      }
    }
    .localization {
      z-index: 2;
      font-size: 14px;
      background-color: $white;
      a {
        color: #575757;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    z-index: 1;
    width: 100%;
    position: fixed;
    padding: 18px 0 20px;
    &+div+div {
      padding-top: 120px;
    }
    .container {
      z-index: 2;
      display: flex;
      position: relative;
      ul {
        display: inline-block;
        li {
          display: inline;
        }
      }
      .logo {
        width: 250px;
        height: 80px;
        background-image: url('./images/logo@2x.png');
      }
      .menuToogle {
        display: none;
      }
      .menu {
        flex: 1;
        margin-top: 45px;
        text-align: right;
        position: relative;
        &:before {
          left: 0;
          z-index: 2;
          content: '';
          width: 100%;
          height: 120px;
          bottom: -20px;
          position: absolute;
          background-color: #fff;
        }
        .main {
          z-index: 3;
          position: relative;
          &+* {
            display: none;
          }
          li {
            &:not(:first-of-type) {
              margin-left: 40px;
            }
            a {
              display: inline-block;
              &:hover:after {
                width: 100%;
              }
              &:after {
                width: 0%;
                height: 3px;
                content: '';
                margin: 7px auto 0;
                display: block;
                transition: width .2s ease-out;
                background-color: $nice-blue;
              }
            }
          }
        }
        .sub {
          top: 57px;
          right: 0;
          z-index: 1;
          position: absolute;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          transition: top .2s;
          &.expand {
            top: -10px;
          }
          &:before {
            top: -1px;
            width: 100%;
            content: '';
            height: .5px;
            position: absolute;
            box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 1);
          }
          ul {
            margin-top: 0;
            padding: 18px 20px 12px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-top: none;
            li:not(:last-of-type) {
              margin-right: 8px;
            }
          }
        }
        .localization {
          top: -40px;
          right: 0;
          position: absolute;
          padding: 4px 7px 2px;
          .active {
            font-weight: bold;
          }
          li:not(:last-of-type):after  {
            content: "|";
            margin: 0 3px;
          }
        }

        .tel {
          top: -39px;
          z-index: 2;
          right: 210px;
          color: #7f7f7f;
          position: absolute;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    z-index: 2;
    width: 100vw;
    position: fixed;
    .container {
      text-align: center;
      > a {
        display: inline-block;
        .logo {
          width: 210px;
          height: 55px;
          transition: all .2s;
          background-image: url('./images/logo-m@2x.png');
          &.fold {
            width: 37px;
            height: 33px;
            margin-left: -110px;
            padding-left: 30px;
            background-image: url('./images/logo-s@2x.png');
            &:after {
              top: 3px;
              color: $nice-blue;
              position: relative;
              white-space: nowrap;
              content: attr(data-title);
            }
          }
        }
      }
      input[type=checkbox] {
        &:checked {
          & + .burger {
            .layer:nth-of-type(2) { opacity: 0; }
            .layer:first-of-type {
              top: 12px;
              transform: rotate(45deg);
            }
            .layer:last-of-type {
              bottom: 12px;
              transform: rotate(-45deg);
            }
          }
          & ~ .menu {
            left: 0;
            @include mixLeftAnimation;
            animation-name: leftAnimation0;
            .sub {
              @include mixLeftAnimation;
              animation-delay: .2s;
              animation-name: leftAnimation120;
            }
            &:before {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
      .burger {
        top: 10px;
        left: 20px;
        width: 30px;
        height: 30px;
        z-index: 12;
        position: fixed;
        transition: left .3s;
        &.fold {
          left: -100%;
        }
        .layer {
          left: 0;
          height: 5px;
          width: 100%;
          position: absolute;
          transition: all .2s;
          background-color: $nice-blue;
          &:first-of-type {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 50%;
            transform: translateY(-50%);
          }
          &:last-of-type {
            bottom: 0;
          }
        }
      }
      .menu {
        top: 0;
        z-index: 10;
        left: -100%;
        height: 100%;
        position: fixed;
        min-width: 144px;
        text-align: left;
        padding: 60px 24px 0;
        transition: left .3s ease-out;
        // @include mixLeftAnimation;
        // animation-name: leftAnimation0;
        a {
          font-size: 16px;
        }
        .main {
          z-index: 2;
          position: relative;
          li {
            margin-bottom: 10px;
            a:active {
              color: $nice-blue;
            }
          }
        }
        .sub {
          top: 65px;
          left: -100%;
          z-index: 1;
          position: fixed;
          transition: left .3s;
          
          ul {
            padding-left: 30px;
            padding-bottom: 30px;
            li {
              margin-top: 13px;
              a:before {
                width: 45px;
              }
            }
          }
        }
        .localization {
          z-index: 2;
          position: relative;
          a {
            padding: 10px 0;
            display: block;
            overflow: hidden;
            text-indent: -100%;
            background-size: 30px;
            background-position: 10px center;
            background-repeat: no-repeat;
            &.zh { background-image: url('./images/taiwan-flag-icon-64.png'); }
            &.en { background-image: url('./images/united-states-of-america-flag-icon-64.png'); }
            &.jp { background-image: url('./images/japan-flag-icon-64.png'); }
            &.kr { background-image: url('./images/south-korea-flag-icon-64.png'); }
          }
        }
        .tel {
          display: none;
        }
        .menuBG {
          content: '';
          width: 150%;
          height: 100%;
          display: block;
          position: absolute;
          background-color: #fFf;
          top: 0;
          right: 0;
          z-index: 1;
        }
        &:before {
          content: '';
          width: 100vw;
          height: 100vh;
          opacity: 0;
          position: fixed;
          background-color: rgba(#000, .5);
          top: 0;
          left: 0;
          z-index: 0;
          pointer-events: none;
          transition: opacity .2s ease-out;
        }
      }
    }
  }
}