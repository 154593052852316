@import "~styles/var";
@import 'bootstrap-4-grid/scss/grid.scss';
.Footer {
  color: #d3d3d3;
  margin: 0 auto;
  max-width: 960px;
  background-color: #4c4c4c;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .indent li{
    margin-left : 3em;
    text-indent : -3em ;
  }

  .logo {
    background-position: center;
    background-repeat: no-repeat;
    &.ngnsa { background-image: url('./images/logo-ngnsa@2x.svg'); }
    &.ync { background-image: url('./images/logo-ync@2x.png'); }
    &.thoa { background-image: url('./images/logo-thoa@2x.png'); }
  }
  .mb li{
    margin-bottom: 5px;
  }

  .mb2{
    margin-bottom: 10px;
  }

  a {
    color: lighten($nice-blue, 20%);
  }
  @include media-breakpoint-up(lg) {
    font-size: 14px;
    padding: 40px 50px;
    position: relative;
    .backTop {
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      border-radius: 5px;
      background-color: $nice-blue;
      &:hover {
        background-color: #fff;
        border: 1px solid $nice-blue;
        &:before {
          border-color: $nice-blue;
        }
      }
      &:before {
        top: 50%;
        left: 50%;
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        display: inline-block;
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .logo {
      height: 156px;
      background-size: 80%;
    }

    .links {
      margin-top: 10px;
      margin-left: 15px;
      img {
        width: 50px;
        margin-right: 20px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: 40px 30px;
    font-size: 12px;
    .logo {
      height: 50px;
      margin-bottom: 50px;
      background-size: contain;
    }

    .center {
      text-align: center;
    }

    .links {
      margin: 20px auto;
      text-align: center;
      img {
        width: 70px;
        margin-right: 10px;
      }
    }
  }
}