@import "~styles/var";
@import 'bootstrap-4-grid/scss/grid.scss';

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: url(~styles/font.woff2) format('woff2');
}

.App {
  background-color: $light-blue-grey;
  background-image: url(~images/wave.png);
  .container {
    max-width: 960px;
    transition: transform .3s ease-out;
    &.shift {
      transform: translateX(144px);
      transition: transform .3s ease-out;
      transition-delay: .1s;
    }
  }
  @include media-breakpoint-up(lg) {
    .expandContact {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      padding-top: 55px;
    }
    .expandContact {
      right: -1px;
      bottom: 0;
      margin: 0;
      z-index: 10;
      padding: 15px;
      position: fixed;
      list-style: none;
      &.toggle {
        background-color: #fff;
        border: 1px solid #c8c8c8;
      }
      a {
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
        display: inline-block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &.fb { background-image: url(./images/fb@2x.png); }
        &.email { background-image: url(./images/email@2x.png); }
        &.phone { background-image: url(./images/phone@2x.png); }
      }
      button {
        width: 35px;
        height: 35px;
        color: #fff;
        font-weight: bold;
        position: relative;
        border-radius: 100%;
        border: 1px solid #fff;
        background-color: rgba(#929292, .5);
        div {
          top: 50%;
          left: 60%;
          position: absolute;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
}